.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  //background: no-repeat top center/150% url('./assets/bg.png');
  @media screen and (max-width: 768px) {
    background: #fff;
  }
}

.container{
  display: flex;
  flex-wrap: nowrap;
}
.content{
  width:1200px;
  @media screen and (max-width: 1200px) {
    width: 100% !important;
    padding: 0 1rem;
  }
}

.text-extra-bold{
  font-family: 'Roboto Bold';
}

.evenBg{
  background: #fff;
}

.oddBg{
  background: #F5F8FE;
}

.divider{
  height: 1px;
  background: rgba(0,0,0,0.12)
}

.footer{
  @media screen and (max-width: 768px) {
    -webkit-padding-bottom: 48px;
  }
}

